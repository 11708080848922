.editform-wrapper.user {

    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0; left: 0;
    background-color: rgba(0,0,0,0.4);
    z-index: 10;
    font-size: 1.3rem;
    
    .form-content {

        position: relative;
        text-align: center;
        width: 100%;
        max-width: 600px;
        background-color: white;
        padding: 50px;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);

        p.title {
            font-size: 25px;
            margin: 0;
        }

        input {

            &[type='text'], &[type='email'],&[type='password'] {
                display: block;
                margin: 20px auto;
                padding: 10px 15px;
                width: 100%;
                box-sizing: border-box;
            }

            &[type='email'] {
                &:first-of-type { margin-top : 50px; }
            }
            
        }


        .btns-wrapper {

            width: 100%;
            margin: 50px auto 0;

            .btn-wrapper {

                width: 50%;
                display: inline-block;
                
                text-align: right; 
                &:first-child { text-align: left; } 

                button {

                    border: 1px black solid;
                    border-radius: 5px;
                    padding: 10px;
                    text-align: center;
                    background-color: white;
                    cursor: pointer;
                    width: 90%;
                    font-size: 18px;

                }
            
            }

        }

    }

}