/* html, body {
	width: 100%;
	height: 100%;
	margin: 0;
}
 */
header {

	.sidebar {

		position: fixed;
		width: 142px;
		height: 100%;
		left: 0; top: 0;
		background-color: #808080;
		text-align: center;
		z-index: 1;

		.logo-header {
			width: 100px;
			margin: 10px;
		}
	
		ul { 

			padding: 0;

			a {
				display: block;
				text-decoration: none;
				list-style: none;
				color: #fff;
				font-size: 1.2rem;
				height: 50px;
				line-height: 50px;
				border-bottom: 1px solid white;
			}

		}

	}

	> a { text-decoration: none; }

	.backToSite {
		position: fixed;
		top: 20px;
		right: 20px;
		padding: 5px 10px;
		border: 1px solid #808080;
		color: #808080;
		border-radius: 5px;
		z-index: 10;
	}

}

.page-content {

	position: absolute;
	width: auto;
	left: 142px; top: 0;
	padding: 20px;

}

