.editform-wrapper.home-image {

    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0; left: 0;
    background-color: rgba(0,0,0,0.4);
    z-index: 10;
    font-size: 1.3rem;
    
    .form-content {

        position: relative;
        text-align: center;
        width: 100%;
        max-width: 600px;
        background-color: white;
        padding: 50px;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);

        p.title {
            font-size: 25px;
            margin: 0;
        }

        .img-wrapper {

            position: relative;
            max-width: 100%;
            min-height : 300px;
            margin: 30px auto;
            border: 1px dashed #333;

            img { max-width: 100%; max-height: 100%; }

            p { 
                position: absolute;
                width: 100%;                
                margin: 0;
                text-align: center;
                color: #333;
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
            }

        }


        .input-wrapper {

            margin: 50px auto;

            label {
                width: 100%;
                border: 1px solid #333;
                padding: 10px;
                border-radius: 5px;
                cursor: pointer;
            }
            p { font-size: 16px; margin: 20px auto; }
            input { display: none; }

        }

        .btns-wrapper {

            width: 100%;
            margin: 50px auto 0;

            .btn-wrapper {

                width: 50%;
                display: inline-block;
                
                text-align: right; 
                &:first-child { text-align: left; } 

                button {

                    border: 1px black solid;
                    border-radius: 5px;
                    padding: 10px;
                    text-align: center;
                    background-color: white;
                    cursor: pointer;
                    width: 90%;
                    font-size: 18px;

                }
            
            }

        }

    }

}