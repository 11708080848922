
.articles-section {

    button {
        border: 1px black solid;
        border-radius: 5px;
        padding: 10px 15px;
        text-align: center;
        background-color: white;
        cursor: pointer;
    }

    .articles-list {

        border: 1px solid #808080;
        border-collapse: collapse;
        margin: 30px auto;

        tr {

            &:nth-child(even) {background-color: #F1F1F1;}

            th, td {
                border: 1px solid #808080;
                border-collapse: collapse;
                margin: 50px auto;
                padding: 15px 30px;
                text-align: left;
            }

            td {

                &.img-wrapper {

                    text-align: center;

                    img { 
                        max-width: 100px;
                        max-height: 200px;
                    }

                }
                

                &.actions {
                    width:  120px;
                }

                .cross{

                    position: relative;
                    top: 0;
                    float: right;
                    right: 0;
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    cursor: pointer;

                    .mdiv, .md{
                        position: absolute;
                        width: 14px;
                        height: 2px;
                        top: 8px;
                        background-color: black;
                        z-index: 1;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }

                    .mdiv{transform: rotate(45deg); -ms-transform: rotate(45deg); /* IE 9 */ -webkit-transform: rotate(45deg); /* Safari and Chrome */}
                    .md{transform: rotate(-45deg); -ms-transform: rotate(-45deg); /* IE 9 */ -webkit-transform: rotate(-45deg); /* Safari and Chrome */}

                }

                .edit-btn {
                    color: -webkit-link;
                    cursor: pointer;
                    text-decoration: underline;
                }

            }

        }

    }

}