$mfont-size: 16px;
$bfont-size: 18px;
$vbfont-size: 22px;
$border-radius: 5px;
$input-color: #afa0a0;
$light-input-color: rgba(175, 160, 160, 0.4);
$verylight-input-color: rgba(175, 160, 160, 0.2);
$error-color: #dc3545;

$item-width: 400px;
$item-margin: 20px;
$btn-height: 50px;

$photo-width: 254px;
$photo-margin: 5px;

html, body{

	font-family: Roboto, Lato, sans-serif;
	width: 100vw;
    overflow-x: hidden;
    height: 100%;
	margin: 0;

    div#root {

        width: 100%;
        height: 100%;

    }

}