@import '../base';

.section-orders {

    margin: 80px auto;
    max-width: 800px;
    padding: 0 20px;

    h1 {
        font-size: 50px;
        font-weight: 300;
        margin-bottom: 30px;
    }

    .confirm {
        margin: 40px 0 0 0;
        font-size: 25px;
        color: #28a745;

        span {
            font-size: 18px;
        }
    }

    .order-wrapper {
        
        padding: 40px 0;
        border-bottom: 1px solid $verylight-input-color;

        .date, .price {
            color: $input-color;
            font-size: 18px;
            font-weight: bold;
        }

        .date {
            text-align: left;
            display: inline;
        }
        .price { float: right; }

        .inner {

            margin: 25px 0 0 10px;

            .statut {
                font-size: 16px;
                span { font-weight: bold; }
            }

            .details {

                margin: 0 0 0 10px;

                p {
                    display: flex;
                    justify-content: space-between;
                    span{
                        color: $input-color;
                    }
                }

            }

        }

    }

}


@media screen and (max-width: 850px){

    .section-orders {
        margin: 30px auto;
        h1 {
            font-size: 35px;
            margin-bottom: 0;
        }
        .order-wrapper {
            .inner {
                margin: 25px auto 0;
                .details {
                    margin: 0 0 0 0;
                    p {
                        span {
                            margin: 0 0 0 20px;
                        }
                    }
                }
            }
        }
    }


}