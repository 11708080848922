@import '../base';

.changepwd-page {

    text-align: center;
    padding: 100px 20px 0;

    img {width: 200px;}

    h1 {
        margin: 50px 0 80px;
        font-weight: inherit;
    }

    p {
        font-size: 18px;
    }

    .changepwd-form {

        input {

            display: block;
            width: 300px;
            font-size: 18px;
            margin: 20px auto;
            padding: 10px 5px;
            border-color: $input-color;
            box-sizing: border-box;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                transition: background-color 9999s ease-in-out 0s;
                -webkit-text-fill-color: black !important;
                
            }

            &:-webkit-autofill::first-line { font-size: 18px !important; }

            &[type="password"] {
                outline: 0;
                border-width: 0 0 2px;
                background-color: transparent !important;
                &.error {
                    border-color: $error-color;
                }
            }
        
            &[type="submit"]{
                cursor: pointer;
                background-color: $input-color;
                color: white;
                border-radius: $border-radius;
                border-style: inherit;
            }

            &:first-child { margin-top: 0; }
            &:focus{ outline: none; }

        }

    }

}