@import '../base';

$cart-padding: 25px 0;

.section-panier {

    width: 800px;
    padding: 50px 0;
    margin: 0 auto;

    h1{
        display: inline;
        font-size: 50px; 
        font-weight: 300; 
        margin-bottom: 30px; 
    }

    > a{
        float: right; 
        margin-top: 2rem;
        font-size: 20px;
    }

    .empty-cart-wrapper {

        text-align: center;
        margin: 80px 0;

        p {
            font-size: 22px;
            margin: 40px 0;
            text-align: center;
        }

        a {

            width: 300px;
            font-size: $bfont-size;
            border: 1px solid $input-color;
            background-color: $input-color;
            color: white;
            padding: 10px 15px;
            border-radius: 7px;
            margin: 20px 0 0 auto;
            text-align: center;
            text-decoration: none;
            cursor: pointer;

        }

    }

    .products-list{

        width: 100%;
        margin: 50px auto;
        border: 1px solid $verylight-input-color;
        border-radius: $border-radius;

        .titles-wrapper {

            display: flex;
            align-items: center;
            padding: $cart-padding;

            .title {

                width: 200px;
                font-size: $bfont-size;
                font-weight: bold;
                text-align: center;

                &:first-of-type {
                    width: 400px;
                }

            }

        }

        .product-wrapper {

            display: flex;
            align-items: center;
            padding: $cart-padding;
            border-top: 1px solid $verylight-input-color;

            .product-section {

                width: 200px;
                text-align: center;

                &.desc-wrapper {
                    
                    display: flex;
                    align-items: center;
                    width: 400px;
                    
                    .img-wrapper {

                        position: relative;
                        width: 100px;
                        height: 150px;
                        min-width: 100px;
                        margin: 0 10px 0 50px;
                        text-align: center;

                        img {
                            position: absolute;
                            top: 50%; left: 50%;
                            transform: translate(-50%, -50%);
                            -webkit-transform: translate(-50%, -50%);
                            -ms-transform: translate(-50%, -50%);
                            -moz-transform: translate(-50%, -50%);
                            -o-transform: translate(-50%, -50%);
                            max-width: 100%;
                            max-height: 100%;
                        }

                    }

                    .text-wrapper {

                        text-align: left;

                        p {

                            margin: 10px auto;

                            &.title {
                                font-weight: bold;
                            }
                            &.desc, &.price {
                                font-style: italic;
                                font-size: $mfont-size;
                            }
                        }
                    }
                    

                }

                &.quantity-wrapper {

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    div {

                        width:40px;
                        height:40px;
                        line-height: 40px;

                        &.moins, &.plus {
                            font-size: 24px;
                            color: white;
                            background-color: $input-color;
                            border-radius: $border-radius;
                            cursor: pointer;
                        }

                    }

                }

                &.total-wrapper {
                    span {
                        display: none;
                    }
                }

            }

        }
    
    }

    .bottom-wrapper{

        text-align: center;
        margin: 50px 0 0 0;
        font-size: $bfont-size;

        .total-wrapper{
            font-weight: bold;
            text-align: right;
        }

        .validate{

            width: 250px;
            border: 1px solid $input-color;
            background-color: $input-color;
            color: white;
            padding: 10px;
            border-radius: 7px;
            margin: 20px 0 0 auto;
            text-align: center;
            cursor: pointer;

        }
        
    }

}


@media screen and (max-width: 850px){

    .section-panier {

        width: 100%;
        padding: 50px 20px;
        box-sizing: border-box;

        .products-list{

            .titles-wrapper {
                display: none;
            }

            .product-wrapper {
                
                flex-wrap: wrap;
                padding: 25px 50px;

                &:first-of-type {
                    border-top: none;
                }

                .product-section {

                    width: auto;
                    &.desc-wrapper {
                        width: 100%;
                        margin: 0 0 25px;

                        .img-wrapper {

                            border: 1px solid $verylight-input-color;
                            border-radius: $border-radius;
                            min-width: 120px;
                            margin: 0 20px 0 0;
                            box-sizing: border-box;

                            img {
                                max-height: 130px;
                            }

                        }
                    }

                    &.quantity-wrapper {

                        justify-content: inherit;
                        margin: 0 20px 0 0;
                        box-sizing: border-box;

                    }

                    &.total-wrapper {
                        text-align: left;
                        font-weight: bold;
                        span {
                            display: inline;
                            font-weight: 100;
                        }
                    }


                }

                

            }

        }

        /* > a{
            float: none;
            margin: 0 auto 50px;
        } */

        /* .empty-cart-wrapper {
            a{
                
            }
        } */
 
    }

}


@media screen and (max-width: 667px){

    .section-panier {

        padding: 20px 20px 80px 20px;

        > a{
            display: block;
            float: none;
            margin: 0 auto;
        }
        h1 {
            display: block;
            margin: 25px auto 0;
        }

        .products-list {
            margin: 25px auto;
        }
    }

}

@media screen and (max-width: 500px){

    .section-panier {
        h1 {
            font-size: 35px;
        }
        .products-list{
            border: none;
            .product-wrapper {
                padding: 30px 0;
                .product-section {
                    &.desc-wrapper {
                        align-items: initial;
                        p.title {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

}
