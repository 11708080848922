@media screen and (max-width: 1200px){

    .login-page {
        .form-wrapper {

            height: 600px;
            top: 50%; left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
            border-radius: $border-radius;

            .form-section {
                img {
                    margin: 0 auto 50px;
                }
            }

        }
    }

    .mdp-page {
        .mdp-form{
            height: 600px;
            padding: 140px 50px;
        }
    }

}

@media screen and (max-width: 640px){

    .login-page {

        width: 100%;
        height: 100%;

        .form-wrapper {

            width: 100%;
            min-width: 100%;
            height: 100%;
            padding: 20px;
            box-sizing: border-box;

            top: 0; left: 0;
            transform: inherit;
            -webkit-transform: inherit;
            -ms-transform: inherit;
			-moz-transform: inherit;
			-o-transform: inherit;

            border-radius: inherit;

            .form-section {

                position: relative;

                left: 0;

                padding: 0;

                transform: translate(0%, -50%);
                -webkit-transform: translate(0%, -50%);
                -ms-transform: translate(0%, -50%);
                -moz-transform: translate(0%, -50%);
                -o-transform: translate(0%, -50%);

                img {
                    margin: -100% auto 50px;
                }

            }

        }
    }

    .mdp-page {
        .mdp-form{
            height: 100%;
            max-width: 100%;
            padding: 140px 20px;
        }
    }

}