@import '../base.scss';

.photos-page {

    .addform-wrapper {

        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0; left: 0;
        background-color: rgba(0,0,0,0.4);
        z-index: 10;
        font-size: 1.3rem;
        
        .form-content {

            position: relative;
            text-align: center;
            width: 100%;
            max-width: 600px;
            border-radius: $border-radius;
            box-sizing: border-box;
            background-color: white;
            padding: 50px;
            margin: auto;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            overflow: auto;


            .cross{
                position: absolute;
                top: 20px; right: 20px;
                width: 30px; height: 30px;
                border-radius: 50%;
                cursor: pointer;
                .mdiv, .md{
                    position: absolute;
                    width: 30px; height: 1px;
                    top: 14px;
                    background-color: black;
                    margin: auto;
                }
                .mdiv{transform: rotate(45deg); -ms-transform: rotate(45deg); /* IE 9 */ -webkit-transform: rotate(45deg); /* Safari and Chrome */}
                .md{transform: rotate(-45deg); -ms-transform: rotate(-45deg); /* IE 9 */ -webkit-transform: rotate(-45deg); /* Safari and Chrome */}
            }


            p.title {
                font-size: 25px;
                margin: 0;
            }

            .img-handler {

                margin: 50px 0 30px;
    
                input[type='file'] { display: none; }
    
                .btn-wrapper {

                    margin: 0 auto 30px;

                    label {
                        display: block;
                        width: 250px;
                        margin: 0 auto;
                        border: 1px solid #333;
                        padding: 10px;
                        border-radius: 5px;
                        cursor: pointer;
                    }

                    p { 
                        font-size: $mfont-size;
                        font-style: italic;
                    }
    
                }
    
                .img-wrapper {
                    
                    position: relative;
                    height: 200px;
                    border: 1px dashed #333;
                    border-radius: $border-radius;

                    p {
                        position: absolute;
                        width: 100%;
                        margin: 0;
                        top: 50%; left: 50%;
                        transform: translate(-50%, -50%);
                        -webkit-transform: translate(-50%, -50%);
                        -moz-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        -o-transform: translate(-50%, -50%);
                    }
    
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                    
                }
    
    
                &.error {
    
                    .btn-wrapper {
                        label {
                            border: 2px solid red;
                        }
                    }
    
                    .img-wrapper {
                        border-color: red;
                    }
    
                }
    
            }

            input[type='text']{
                width: 100%;
                display: block;
                margin: 20px auto;
                padding: 10px 15px;
                border-radius: $border-radius;
                outline: none;
                border: 1px solid;
                &.error {
                    border: 2px solid red;
                }
                &:focus {
                    outline: none;
                }
            }

            .btns-wrapper {

                margin: 50px auto 0;

                button {

                    width: 100%;
                    border: 1px solid $input-color;
                    border-radius: $border-radius;
                    padding: 10px;
                    box-sizing: border-box;
                    font-size: $bfont-size;
                    margin: 0;
                    cursor: pointer;

                    &.validate {
                        background-color: $input-color;
                        color: white;
                        margin-bottom: 10px;
                    }

                    &.cancel {
                        color: $input-color;
                        background-color: white;
                    }

                }

            }

        }

    }

}

@media screen and (max-width: 640px){

    .photos-page {
        .addform-wrapper {
            .form-content {
                max-width: inherit;
                height: 100%;
                padding: 50px 20px;
                .img-handler {
                    .btn-wrapper {
                        label {
                            width: 100%;
                            box-sizing: border-box;
                        }
                    }
                }
            }
        }
    }

}