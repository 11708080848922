.notfound-page {

    width: 100%;
    text-align: center;
    padding: 100px 0 0 0;

    img {
        width: 200px;
    }

}