@import './base';

.items-page{

	width: 100%;
	max-width: 3 * ($item-width + 2 * $item-margin);
	margin: 0 auto;
	padding: 50px 0;
	text-align: center;	

	p.message{
		text-align: center;
		margin: 50px auto;
		font-size: 22px;
	}

	.items-list{

		list-style: none;
		text-align: left;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin: 20px auto;
		padding: 0;

		li{

			width: $item-width;
			margin: $item-margin;
			border: 1px solid $verylight-input-color;
			border-radius: $border-radius;
			padding: 10px;
			box-sizing: border-box;

			.item-img{

				text-align: center; 
				height: 250px; 
				overflow: hidden;
				border: 1px solid $verylight-input-color;
				border-radius: $border-radius;

				img{
					max-height: 100%;
					max-width: 100%;
					top: 50%;
					position: relative;
					padding: 10px 0;
					box-sizing: border-box;
					-webkit-transform: translateY(-50%);
					-moz-transform:    translateY(-50%);
					-ms-transform:     translateY(-50%);
					-o-transform:      translateY(-50%);
					transform:         translateY(-50%);
				}

			}

			.item-desc {

				text-align: left;
				margin: 20px auto;
				font-size: $bfont-size;

				p {
					margin: 0;
				}

				.fline {
					font-weight: bold;
					display: flex;
					justify-content: space-between;
					margin: 20px auto 10px;
				}

				.desc {
					p {
						font-size: $mfont-size;
						line-height: $mfont-size;
						min-height: 2 * $mfont-size;
					}
				}


			}

			.item-btn, .unsusc {

				text-align: center;
				width: 100%;
				height: $btn-height;
				font-size: 1.3rem;
				border-radius: $border-radius;
				background-color: $input-color;
				color: white;
				overflow: hidden;
				cursor: pointer;

				p {
					line-height: $btn-height;
					margin: 0 auto;
					transition: margin-top 0.1s ease;
					-webkit-transition: margin-top 0.1s ease;
					-moz-transition: margin-top 0.1s ease;
					-ms-transition: margin-top 0.1s ease;
				}

				.checkmark-wrapper {

					height: $btn-height;
					display: flex;
					align-items: center;
					justify-content: center;

					.checkmark {
						display: inline-block;
						transform: rotate(45deg);
						height: 24px;
						width: 12px;
						border-bottom: 6px solid white;
						border-right: 6px solid white;
						margin-top: -6px;
					}
				}

				&.check {
					p { margin-top: -$btn-height;}
				}

			}

		}

	}

}


@media screen and (max-width: (3 * ($item-width + 2 * $item-margin) + 20px)){
	
	.items-page{
		max-width: 2 * ($item-width + 2 * $item-margin);
	}

}

@media screen and (max-width: (2 * ($item-width + 2 * $item-margin) + 20px)){
	
	.items-page{
		padding: 0 20px 50px 20px;
		box-sizing: border-box;
		.items-list{

			display: inherit;
			margin: 0 auto;

			li{ 

				max-width: 100%;
				margin: 50px auto;
				padding: 0;
				border: none;

				.item-desc {
					.fline {
						.title {
							margin-right: 20px;
						}
					}
				}

			}
		}
	}

}