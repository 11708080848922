@import '../base';

.events-page {

    .items-list{

        li {

            position: relative;

            .date, .location {

                display: flex;
                align-items: center;
                margin: 20px auto 0;

                img {
                    width: 20px;
                    height: 20px;
                    margin: 0 10px 0 0;
                }

                p {
                    font-size: $mfont-size;
                }

            }



            .users {

                width: 100%;
                height: $btn-height;
                margin-top: 10px;
                font-size: $bfont-size;
                border-radius: $border-radius;
                border: 1px solid $input-color;
                overflow: hidden;
                text-align: center;
                box-sizing: border-box;
                cursor: pointer;

                p { 
                    line-height: $btn-height;
                    margin: 0 auto;
                }
            }
            
            .users-list {

                display: none;
                position: absolute;
                top: 0; left: 0;
                height: calc(100% - 2 * $btn-height - 30px);
                width: 100%;
                padding: 10px 20px;
                background-color: white;
                border-bottom: 1px solid $verylight-input-color;
                overflow: auto;
                box-sizing: border-box;

                &.show { display: block; }

                .cross{

                    position: relative;
                    top: 5%;
                    float: right;
                    right: 0;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    z-index: 2;

                    .mdiv, .md{
                        position: absolute;
                        width: 30px;
                        height: 1px;
                        top: 14px;
                        background-color: black;
                        z-index: 1;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }

                    .mdiv{transform: rotate(45deg); -ms-transform: rotate(45deg); /* IE 9 */ -webkit-transform: rotate(45deg); /* Safari and Chrome */}
                    .md{transform: rotate(-45deg); -ms-transform: rotate(-45deg); /* IE 9 */ -webkit-transform: rotate(-45deg); /* Safari and Chrome */}

                    &:hover{cursor: pointer;}

                }

                .nobody {

                    position: relative; 
                    height: 100%; 
                    text-align: center;

                    p {
                        position: relative;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        -moz-transform:    translateY(-50%);
                        -ms-transform:     translateY(-50%);
                        -o-transform:      translateY(-50%);
                        transform:         translateY(-50%);
                        margin: 0;
                        font-size: 1.5rem;
                    }

                }

            }

        }

    }

    .form-wrapper {

        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0; left: 0;
        background-color: rgba(0,0,0,0.4);
        z-index: 10;
        overflow: auto;
        
        .form {

            position: relative;
            text-align: center;
            width: 100%;
            max-width: 600px;
            box-sizing: border-box;
            background-color: white;
            padding: 50px;
            margin: auto;
            border-radius: $border-radius;
            top: 50%;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);

            .cross{
                position: absolute;
                top: 20px; right: 20px;
                width: 30px; height: 30px;
                border-radius: 50%;
                cursor: pointer;
                .mdiv, .md{
                    position: absolute;
                    width: 30px; height: 1px;
                    top: 14px;
                    background-color: black;
                    margin: auto;
                }
                .mdiv{transform: rotate(45deg); -ms-transform: rotate(45deg); /* IE 9 */ -webkit-transform: rotate(45deg); /* Safari and Chrome */}
                .md{transform: rotate(-45deg); -ms-transform: rotate(-45deg); /* IE 9 */ -webkit-transform: rotate(-45deg); /* Safari and Chrome */}
            }

            .event-infos {

                padding-bottom: 25px;
                border-bottom: 1px solid $verylight-input-color;
                font-size: $mfont-size;

                p {
                    margin: 0;
                    &.title {
                        font-size: $vbfont-size;
                        font-weight: bold;
                        margin: 0 0 10px 0;
                    }
                    &.desc {
                        margin: 0 0 20px 0;
                    }
                }
                .date, .location {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 10px auto 0;
                    img {
                        width: 20px;
                        height: 20px;
                        margin: 0 10px 0 0;
                    }
                }
            }

            .form-content {
                padding-top: 25px;
                p {
                    margin: 0;
                    font-size: $bfont-size;
                }
                .select-wrapper {
                    margin: 25px auto 40px;
                    label {
                        margin-right: 20px;
                        font-size: $bfont-size;
                    }
                    select {
                        padding: 10px;
                        border-radius: $border-radius;
                    }
                }
                .submit, .cancel{
                    font-size: $bfont-size;
                    padding: 10px 0;
                    margin: 0 auto;
                    border-radius: $border-radius;
                    cursor: pointer;
                }
                .submit {
                    color: white;
                    background-color: $input-color;
                }
                .cancel {
                    margin-top: 10px;
                    border: 1px solid $input-color;
                    color: $input-color;
                }
            }

        }

    }

}


@media screen and (max-width: 667px){

    .events-page {
        .form-wrapper {
            background-color: white;
            .form {
                width: 100%;
                box-sizing: border-box;
                .form-content {
                    .submit, .cancel{
                        width: 100%;
                        max-width: 300px;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }

}