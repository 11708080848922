.img-section {

    padding: 0 50px;

    .img-wrapper {

        position: relative;
        width: 800px;
        margin: 50px auto;

        &.accueil1 {display: none;}

        img {
            width: 100%;
        }

        &:hover {
            .img-mask {
                display: block;
            }
        }

        .img-mask {

            display: none;
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);

            .edit-btn {

                position: absolute;
                width: 200px;
                top: 50%; left: 50%;
                border: 1px black solid;
                border-radius: 5px;
                padding: 10px 15px;
                text-align: center;
                background-color: white;
                cursor: pointer;

                

                transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);

            }

        }

    }

}