@import '../base';

.articles-page{

	select#type{
		padding: 5px 10px;
		font-size: 1.2rem;
		border-radius: 5px;
		cursor: pointer;
	}

	.items-list{
		li {
			.item-desc {
				.quantity-wrapper {

					display: flex;
					align-items: center;
					justify-content: left;
					margin: 20px auto 0;

					div {

						width:40px;
						height:40px;
						line-height: 40px;
						text-align: center;

						&.moins, &.plus {
							font-size: 24px;
							color: white;
							background-color: $input-color;
							border-radius: $border-radius;
							cursor: pointer;
						}

					}

				}
			}

			&.out-of-stock {
				.item-desc {
					.quantity-wrapper {
						div {

							&.quantity {
								color: $light-input-color;
							}

							&.moins, &.plus {
								background-color: $light-input-color;
								cursor: inherit;
							}

						}
					}
				}			
				.item-btn {
					background-color: $light-input-color;
					cursor: inherit;
				}
			}
		}
	}

}

@media screen and (max-width: (2 * ($item-width + 2 * $item-margin) + 20px)){
	
	.articles-page{
		select#type{
			margin: 50px auto 0;
		}
	}

}