.form-wrapper.article, .form-wrapper.event {

    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0; left: 0;
    background-color: rgba(0,0,0,0.4);
    z-index: 10;
    font-size: 1.3rem;
    overflow: auto;
    
    .form-content {

        position: relative;
        text-align: center;
        width: 100%;
        max-width: 600px;
        background-color: white;
        padding: 50px;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);

        p.title {
            margin: 0 auto;
            font-size: 25px;
        }

        select {
            display: block;
            margin: 50px auto 20px;
            padding: 10px 15px;
            width: 100%;
            box-sizing: border-box;
        }

        input {

            &[type='text'], &[type='number'] {
                display: block;
                margin: 20px auto;
                padding: 10px 15px;
                width: 100%;
                box-sizing: border-box;
            }

            &.error {
                border-color: red;
            }
        }

        .img-handler {

            position: relative;
            margin: 30px 0;

            input[type='file'] { display: none; }

            .btn-wrapper, .img-wrapper {
                position: relative;
                display: inline-block;
                vertical-align: top;
                box-sizing: border-box;
                height: 200px;
                padding: 0 10px;
            }

            .btn-wrapper {

                width: 40%;

                .text-wrapper {

                    position: absolute;
                    width: 100%;
                    top: 50%; left: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%);

                    label {
                        width: 100%;
                        border: 1px solid #333;
                        padding: 10px;
                        border-radius: 5px;
                        cursor: pointer;
                    }

                    p {
                        font-size: 16px;
                    }
                }

            }

            .img-wrapper {

                border: 1px dashed #333;
                width: 60%;

                p {
                    position: absolute;
                    width: 100%;
                    margin: 0;
                    top: 50%; left: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    -moz-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    -o-transform: translate(-50%, -50%);
                }

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
                
            }


            &.error {

                .btn-wrapper {
                    .text-wrapper {
                        label {
                            border: 2px solid red;
                        }
                    }
                }

                .img-wrapper {
                    border-color: red;
                }

            }

        }

        .btns-wrapper {

            width: 100%;
            margin: 50px auto 0;

            .btn-wrapper {

                width: 50%;
                display: inline-block;
                
                text-align: right; 
                &:first-child { text-align: left; } 

                button {

                    border: 1px black solid;
                    border-radius: 5px;
                    padding: 10px;
                    text-align: center;
                    background-color: white;
                    cursor: pointer;
                    width: 90%;
                    font-size: 18px;

                }
            
            }

        }

    }

}