@import '../../base.scss';

.stats-section {

    width: 100%;

    select {
        padding: 5px 10px;
        font-size: $bfont-size;
        border-radius: 5px;
        cursor: pointer;
    }

    table {

        border: 1px solid #808080;
        border-collapse: collapse;
        margin: 30px auto;

        tr {

            &:nth-child(even) {background-color: #F1F1F1;}

            th, td {
                border: 1px solid #808080;
                border-collapse: collapse;
                margin: 50px auto;
                padding: 15px 30px;
                text-align: left;

                p {
                    margin: 0;
                    &.title {
                        font-weight: bold;
                        margin-bottom: 10px;
                    }
                }

            }

            .year {
                text-align: center;
                font-size: $bfont-size;
                font-weight: bold;
            }

        }

    }

}