.commandes-section {

    .commandes-list {

        border: 1px solid #808080;
        border-collapse: collapse;
        margin: 50px auto;

        tr {

            &:nth-child(even) {background-color: #F1F1F1;}

            th, td{
                border: 1px solid #808080;
                border-collapse: collapse;
                margin: 50px auto;
                padding: 15px 30px;
                text-align: left;

                

                &.actions{

                    width:  120px;

                    .cross{

                        position: relative;
                        top: 0;
                        float: right;
                        right: 0;
                        width: 17px;
                        height: 17px;
                        border-radius: 50%;
                        cursor: pointer;

                        .mdiv, .md{
                            position: absolute;
                            width: 14px;
                            height: 2px;
                            top: 8px;
                            background-color: black;
                            z-index: 1;
                            left: 0;
                            right: 0;
                            margin: auto;
                        }

                        .mdiv{transform: rotate(45deg); -ms-transform: rotate(45deg); -webkit-transform: rotate(45deg);}
                        .md{transform: rotate(-45deg); -ms-transform: rotate(-45deg); -webkit-transform: rotate(-45deg);}

                    }

                    .modify, .save{
                        border: 1px solid black;
                        padding: 5px 10px;
                        border-radius: 7px;
                        display: inline;
                        cursor: pointer;
                    }

                }

            }

        }

    }

}

