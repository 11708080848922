@import '../base';

$user-dashboard-header-height: 90px;

header.user-dashboard {

	position: relative;
	width: 100vw;
	height: $user-dashboard-header-height;
	color: black;

    ul.menu-list {

        float: right;
        height: $user-dashboard-header-height;
        margin: 0 100px 0 0;

        a {

            text-decoration: none;
            list-style: none;
            color: #000;
            font-size: 1.5rem;
            display: inline-block;
            height: 65px;
            line-height: $user-dashboard-header-height;
            margin: 0 20px;

            &:last-child{ margin-right: 0 }
            &.current{ border-bottom: 1px solid black; }
            &.logo-link-resp{ display: none; }

        }

    }

    .logo-header{
        position: relative;
        width: 130px;
        margin-left: 100px;
        float: left;
        top: 6px;
    }

}

@media screen and (min-width: 1310px) and (max-width: 1360px){header.user-dashboard ul.menu-list a{margin: 0 15px;}}
@media screen and (max-width: 1310px){


    .site-container {

        height: 100%;
        overflow: hidden; 
        /*overflow-y: scroll; -webkit-overflow-scrolling: touch;*/

        .site-pusher{

            height: 100%;
            -webkit-transform: translateX(0px);
            -webkit-transition-duration: 0.3s;
            transform: translateX(0px);
            transition-duration: 0.3s;

            header.user-dashboard {

                position: static;
        
                .logo-header {
                    margin-right: 50px;
                    float: right;
                }
        
                #nav-toggle-container {
        
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    width: 40px;
                    height: 40px;
                    margin: 20px 0 0 20px;
                    z-index: 10;
        
                    &:hover{cursor: pointer;}
        
                    #nav-toggle{
        
                        position: absolute;
                        width: 100%;
                        top: 20px;
                        text-align: center;
        
                        span, span:before, span:after {
                            content: '';
                            height: 3px;
                            width: 26px;
                            left: 0; right: 0;
                            margin: auto;
                            display: block;
                            border-radius: 1px;
                            background: black;
                        }
                        span:before {position: relative; top: -7px; left: 0;}
                        span:after {position: relative; top: 4px; left: 0;}
                        span, span:before, span:after {-webkit-transition: all 500ms ease-in-out; transition: all 500ms ease-in-out;}
                        &.active span {background-color: transparent;}
                        &.active span:before {top: 0; -webkit-transform: rotate(45deg); transform: rotate(45deg);}
                        &.active span:after {top: -3px; -webkit-transform: rotate(-45deg); transform: rotate(-45deg);}
        
                    }
        
                }
        
                ul.menu-list {
        
                    display: none;
                    position: absolute;
                    top: 0; left: 0; bottom: 0;
                    width: 250px;
                    height: 100%;
                    background-color: #FFF;
                    text-align: center;
                    z-index: 2;
                    padding: 0;
                    margin: 0;
                    -webkit-transform: translateX(-250px);
                    transform: translateX(-250px);
                    overflow-y: auto; 
                    -webkit-overflow-scrolling: touch;
        
                    a {
        
                        display: block; 
                        height: auto; 
                        line-height: 100%; 
                        padding: 20px 0;
                        
                        &:last-child{ margin-right: 20px; }
                        &.current{border: none; font-weight: bold;}
        
                        &.logo-link-resp{
        
                            display: block; 
                            padding: 40px 0;
        
                            li.logo-resp{
        
                                width: 100px;
                                height: 60px;
                                margin: auto;
            
                                img { width: 100%; }
            
                            }
        
                        }
        
                    }
        
                }
        
            }

        }

        .site-content-wrapper{
            position: absolute;
            top: 0; left: 0; right: 0; bottom: 0;
            width: 100%;
            height: 100%;
            padding-top: $user-dashboard-header-height;
            overflow-x: hidden;
            overflow-y: auto; 
            -webkit-overflow-scrolling: touch;
            .site-content {
                margin-bottom: $user-dashboard-header-height;
            }
        }

    }

    body.with--sidebar {

        .site-container {

            .site-pusher{

                transform : translateX(250px); 
                -webkit-transform : translateX(250px);

                header.user-dashboard {
                    ul.menu-list{display: block;}
                }

                .site-cache{
                    position: absolute;
                    top: 0; right: 0; bottom: 0; left: 0;
                    height: 100%;
                    background-color: rgba(0,0,0,0.6);
                    z-index: 10;
                    cursor: pointer;
                }

            }

        }

    }

}

@media screen and (max-width: 500px){
    .site-container {
        .site-pusher{
            header.user-dashboard { 
                .logo-header {margin: 0 20px 0 0;}
            }
        }
    }
}