@import '../base';

.login-page {

	overflow: hidden;
	width: 100%;
	height: 100%;

	img.background {
		min-width: 100%;
		min-height: 100%;
		width: 100%;
		position: relative;
		top: 50%; left: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
	}
	
	.form-wrapper {

		width: 33.3%;
		height: 100%;
		min-width: 600px;
		z-index: 1;
		position: absolute;
		top: 0; left: 0;
		background-color: rgba(255, 255, 255, 0.85);
		border-top-right-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
		box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;

		.form-section {

			width: 100%;
			padding: 100px 20px;
			text-align: center;
			box-sizing: border-box;
			background-color: transparent;

			position: absolute;
			top: 50%; left: 50%;
			transform: translate(-50%, -50%);
			-webkit-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
	
			img {

				width: 200px;
				margin: -100% auto 50px;

			}
	
			.form {

				input{

					display: block;
					width: 300px;
					font-size: $bfont-size;
					margin: 20px auto;
					padding: 10px 5px;
					border-color: $input-color;
					box-sizing: border-box;

					&:-webkit-autofill,
					&:-webkit-autofill:hover,
					&:-webkit-autofill:focus,
					&:-webkit-autofill:active {
						transition: background-color 99999s ease-in-out 0s;
						-webkit-text-fill-color: black !important;
						
					}

					&:-webkit-autofill::first-line { font-size: $bfont-size !important; }

					&[type="text"], &[type="password"] {
						outline: 0;
						border-width: 0 0 2px;
						background-color: transparent !important;
					}
				
					&[type="submit"]{
						cursor: pointer;
						background-color: $input-color;
						color: white;
						border-radius: $border-radius;
						border-style: inherit;
					}

					&:first-child { margin-top: 0; }
					&:focus{ outline: none; }
	
				}
	
			}

			.oubli-mdp{
				font-size: 16px;
				text-decoration: underline;
				color: $input-color; 
				margin: 0 auto;
				cursor: pointer;
			}
	
		}

	}

}

/*****************
*   PWD POPUP   *
*****************/

.mdp-page {

	position: fixed;
	width: 100%;
	height: 100%;
	top: 0; left: 0;
	background-color: rgba(0,0,0,0.4);
	z-index: 10;
	
	.mdp-form{

		position: relative;
		text-align: center;
		max-width: 600px;
		z-index: 2;
		background-color: white;
		border-radius: $border-radius;
		padding: 100px 50px;
		box-sizing: border-box;
		margin: auto;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		overflow: auto;

		p {
			font-size: $bfont-size;
			margin: 0 auto;
			span {
				font-style: italic;
			}
		}

		input{
			display: block;
			width: 100%;
			font-size: $bfont-size;
			margin: 50px auto;
			padding: 10px 5px;
			outline: 0;
			border-width: 0 0 2px;
			box-sizing: border-box;
			&.error {
				border-color: $error-color;
			}
		}

		.submit, .cancel {
			padding: 10px 0;
			font-size: $bfont-size;
			border: 1px solid $input-color;
			border-radius: $border-radius;
			box-sizing: border-box;
			cursor: pointer;
		}
		.submit {
			background-color: $input-color;
			margin: 0 auto 15px;
			color: white;
		}
		.cancel {
			color: $input-color;
		}

		.cross{
			position: absolute;
			top: 20px; right: 20px;
			width: 30px; height: 30px;
			border-radius: 50%;
			cursor: pointer;
			.mdiv, .md{
				position: absolute;
				width: 30px; height: 1px;
				top: 14px;
				background-color: black;
				margin: auto;
			}
			.mdiv{transform: rotate(45deg); -ms-transform: rotate(45deg); /* IE 9 */ -webkit-transform: rotate(45deg); /* Safari and Chrome */}
			.md{transform: rotate(-45deg); -ms-transform: rotate(-45deg); /* IE 9 */ -webkit-transform: rotate(-45deg); /* Safari and Chrome */}
		}

	}

}

@import './login-responsive';
