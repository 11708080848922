@import '../base.scss';

.photos-page {
    
    width: 100%;
    max-width: 5 * ($photo-width + 2 * $photo-margin) ;
    margin: 0 auto;
    padding: 50px 0;
    text-align: center;

    .add-btn {
        width: 250px;
        font-size: $bfont-size;
        border: 1px solid $input-color;
        color: $input-color;
        padding: 10px;
        border-radius: $border-radius;
        margin: 0;
        text-align: center;
        cursor: pointer;
    }

    .message {
        text-align: center;
        font-size: $bfont-size;
    }

    .photos-section {
        
        display: flex;
        flex-wrap: wrap;

        .date {
            width: 100%;
            margin: 30px auto 0px;
            padding: 0 0 10px 0;
            text-align: left;
            font-size: 22px;
            font-weight: bold;
            border-bottom: 1px solid $light-input-color;
        }

        .photo-wrapper {

            width: $photo-width;
            height: 200px;
            margin: 0 $photo-margin;
            cursor: pointer;

            img {
                max-height: 100%;
                max-width: 100%;
                top: 50%;
                position: relative;
                padding: 10px 0;
                box-sizing: border-box;
                -webkit-transform: translateY(-50%);
                -moz-transform:    translateY(-50%);
                -ms-transform:     translateY(-50%);
                -o-transform:      translateY(-50%);
                transform:         translateY(-50%);
            }

        }
    }
    
}


@media screen and (max-width: (5 * ($photo-width + 2 * $photo-margin) + 40px)){
    .photos-page {
        max-width: 4 * ($photo-width + 2 * $photo-margin);
    }
}

@media screen and (max-width: (4 * ($photo-width + 2 * $photo-margin) + 40px)){
    .photos-page {
        max-width: 3 * ($photo-width + 2 * $photo-margin);
    }
}

@media screen and (max-width: (3 * ($photo-width + 2 * $photo-margin) + 40px)){
    .photos-page {
        max-width: 2 * ($photo-width + 2 * $photo-margin);
    }
}

@media screen and (max-width: (2 * ($photo-width + 2 * $photo-margin) + 40px)){
    .photos-page {
        padding: 0 20px 50px 20px;
        box-sizing: border-box;
        .add-btn {
            width: inherit;
            box-sizing: border-box;
            margin: 50px auto;
        }
        .photos-section {
            display: inherit;
            .date {
                text-align: center;
            }
            .photo-wrapper {
                width: 100%;
                margin: 0 auto;
                height: inherit;
                img {
                    -webkit-transform: inherit;
                    -moz-transform:    inherit;
                    -ms-transform:     inherit;
                    -o-transform:      inherit;
                    transform:         inherit;
                }
            }
        }
    }
}


