@import '../base.scss';

.photos-page {

    .carousel-wrapper {

        position: fixed;
        top: 0; left: 0;
        width:  100%;
        height: 100%;
        box-sizing: border-box;

        *:not(.loader-container, .loader-container *, .meta, .meta *) {
            width:  100%;
            height: 100%;
        }

        .carousel-section {

            .cross{
                position: absolute;
                top: 0; right: 0;
                width: 100px; height: 100px;
                z-index: 1;
                cursor: pointer;
                &:hover {
                    background-color: $verylight-input-color;
                }
                .mdiv, .md{
                    position: absolute;
                    width: 30px; height: 1px;
                    top: 50%; left: 50%;
                    background-color: black;
                    margin: auto;
                }
                .mdiv{
                    transform: translate(-50%,-50%) rotate(45deg); 
                    -ms-transform: translate(-50%,-50%) rotate(45deg); /* IE 9 */ 
                    -webkit-transform: translate(-50%,-50%) rotate(45deg); /* Safari and Chrome */
                }
                .md{
                    transform: translate(-50%,-50%) rotate(-45deg); 
                    -ms-transform: translate(-50%,-50%) rotate(-45deg); /* IE 9 */ 
                    -webkit-transform: translate(-50%,-50%) rotate(-45deg); /* Safari and Chrome */
                }
            }
            
            .loader-container {

                margin: 0 auto;

                position: absolute;
                top: 50%; left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform:    translate(-50%, -50%);
                -ms-transform:     translate(-50%, -50%);
                -o-transform:      translate(-50%, -50%);
                transform:         translate(-50%, -50%);

            }

            .carousel-root.carousel {

                .carousel-slider.carousel {

                    .arrow-wrapper {

                        width: 100px;
                        height: calc(100% - 100px);
                        position: absolute;
                        top: 100px;
                        z-index: 1;
                        cursor: pointer;

                        &:hover {
                            background-color: $verylight-input-color;
                        }

                        .arrow {
                            display: block;
                            margin: 0 auto;
                            width: 25px;
                            height: 25px;
                            border-top: 2px solid #000;
                            border-left: 2px solid #000;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                        }

                        &.left {
                            left: 0;
                            .arrow {
                                -webkit-transform: translate(-50%, -50%) rotate(-45deg);
                                -moz-transform:    translate(-50%, -50%) rotate(-45deg);
                                -ms-transform:     translate(-50%, -50%) rotate(-45deg);
                                -o-transform:      translate(-50%, -50%) rotate(-45deg);
                                transform:         translate(-50%, -50%) rotate(-45deg);
                            }
                        }
                        &.right {
                            right: 0;
                            .arrow {
                                -webkit-transform: translate(-50%, -50%) rotate(135deg);
                                -moz-transform:    translate(-50%, -50%) rotate(135deg);
                                -ms-transform:     translate(-50%, -50%) rotate(135deg);
                                -o-transform:      translate(-50%, -50%) rotate(135deg);
                                transform:         translate(-50%, -50%) rotate(135deg);
                            }
                        }
                    }

                    .slider-wrapper {

                        .slider {

                            .slide {

                                background-color: white;

                                .slider-item {

                                    height: calc(100% - 150px);
                                    position: relative;

                                    img {
                                        width: auto;
                                        height: auto;
                                        max-width: 100%;
                                        max-height: 100%;
                                        position: absolute;
                                        top: 50%; left: 50%;
                                        -webkit-transform: translate(-50%, -50%);
                                        -moz-transform:    translate(-50%, -50%);
                                        -ms-transform:     translate(-50%, -50%);
                                        -o-transform:      translate(-50%, -50%);
                                        transform:         translate(-50%, -50%);
                                    }

                                }

                                .meta {
                                    width: 100%;
                                    height: 150px;
                                    position: absolute;
                                    bottom: 0;
                                    padding: 0 20px;
                                    p {
                                        margin: 0 auto;
                                        font-size: $mfont-size;
                                        line-height: $mfont-size;
                                        &.desc {
                                            margin: 28px auto 10px;
                                            font-size: $bfont-size;
                                            line-height: $bfont-size;
                                        }

                                    }
                                }

                            }

                        }

                    }

                }

            }

        }
    
    }

}

@media screen and (max-width: 667px){
    .photos-page {
        .carousel-wrapper {
            .carousel-section {
                .cross{
                    width: 80px; height: 80px;
                }
                .carousel-root.carousel {
                    .carousel-slider.carousel {
                        .arrow-wrapper {

                            width: 50%;
                            background-color: transparent;
                            -webkit-tap-highlight-color: transparent;
                            -webkit-touch-callout: none;
                            -webkit-user-select: none;
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;

                            &:hover {
                                background-color: transparent;
                            }
                            &:focus {
                                outline: none !important;
                            }

                            .arrow {
                                display: none;
                            }

                        }
                    }
                }
            }
        }
    }
}